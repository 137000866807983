.mapboxgl-directions-container {
  display: block;
  clear: both;
  margin-right: 10px;
}

.mapboxgl-style-list
{
  display: none;
  flex-direction: column;
  float: right;
}

.mapboxgl-ctrl-group .mapboxgl-style-list button
{
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 8px 8px 6px;
  text-align: right;
  width: 100%;
  height: auto;
}

.mapboxgl-style-list button.active
{
  background-color: rgba(35, 148, 204, 0.8);
}

.mapboxgl-style-list button:hover
{
  background-color: rgba(0, 0, 0, 0.5);
}

.mapboxgl-style-magnifying-glass
{
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTUwMi42MSAyMzMuMzJMMjc4LjY4IDkuMzljLTEyLjUyLTEyLjUyLTMyLjgzLTEyLjUyLTQ1LjM2IDBMOS4zOSAyMzMuMzJjLTEyLjUyIDEyLjUzLTEyLjUyIDMyLjgzIDAgNDUuMzZsMjIzLjkzIDIyMy45M2MxMi41MiAxMi41MyAzMi44MyAxMi41MyA0NS4zNiAwbDIyMy45My0yMjMuOTNjMTIuNTItMTIuNTMgMTIuNTItMzIuODMgMC00NS4zNnptLTEwMC45OCAxMi41NmwtODQuMjEgNzcuNzNjLTUuMTIgNC43My0xMy40MyAxLjEtMTMuNDMtNS44OFYyNjRoLTk2djY0YzAgNC40Mi0zLjU4IDgtOCA4aC0zMmMtNC40MiAwLTgtMy41OC04LTh2LTgwYzAtMTcuNjcgMTQuMzMtMzIgMzItMzJoMTEydi01My43M2MwLTYuOTcgOC4zLTEwLjYxIDEzLjQzLTUuODhsODQuMjEgNzcuNzNjMy40MyAzLjE3IDMuNDMgOC41OSAwIDExLjc2eiIvPjwvc3ZnPg==);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 65%;

  border-radius: 50px;
  border-style: solid;
  border-width: 0px;
  background-color: rgba(0,0,0,0.4);

  clear: both;
  pointer-events: auto;
  transform: translate(0);

  float: right;

  color: #000;

  width: 35px;
  height: 35px;

  cursor: pointer;
  margin-top: 10px;
}

.mapboxgl-style-layer
{
  border-radius: 4px;
  border-style: solid;
  border-width: 0px;
  background-color: rgba(0,0,0,0.4);

  color: #ffffff;

  clear: both;
  pointer-events: auto;

  float: right;

  min-width: 35px;
  width: 35px;
  height: 35px;
  
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
}
