canvas {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.mapboxgl-popup-content {
  padding: 5px !important;
  border-radius: 12px !important;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 50%) !important;
  width: 300px;
  background-color: rgba(255, 255, 255, 0.85) !important;
}

.MuiPaper-rounded {
  border-radius: 10px !important;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active
{
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


/* Works on Firefox */
* {
  /*scrollbar-width: thin;*/
  scrollbar-color: #DDE9F9;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #DDE9F9;
  border-radius: 10px;
  border: 1px solid white;
}

/* Marker tweaks */
.mapboxgl-popup-close-button {
  font-size: 1.75em;
  color: #78A9FF !important;
}

.mapboxgl-popup-content {
  padding: 0;
  width: fit-content !important;
}

.mapboxgl-popup-content-wrapper {
}

.mapboxgl-popup-content h3 {
}

.mapboxgl-popup-content h4 {
}

.mapboxgl-popup-content div {
}

.mapboxgl-container .leaflet-marker-icon {
}

.mapboxgl-popup-tip {
  padding-bottom: 10px !important
}

.mapboxgl-popup-anchor-bottom {
  max-width: max-content !important
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
}

.MuiPaper-elevation1 {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 6px 0px rgb(0 0 0 / 50%) !important;
}

.MuiButton-root {
  border-radius: 25px !important;
  max-width: fit-content !important;
}

.MuiTableCell-head {
  font-weight: bold !important;
  font-size: 1.1em !important;
}

.MuiDialog-paper {
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
}

.MuiDialogContent-root {
  overflow-x: hidden;
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('./fonts/ProximaNovaRegular400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('./fonts/ProximaNovaRegular600.woff2') format('woff2');
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('./fonts/ProximaNovaRegular700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('./fonts/ProximaNovaItalic400.woff2') format('woff2');
  font-weight: 400;
  font-style: italic
}

.sliderButton {
  background: #fff;
  border: 0;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
  width: 23px;
  height: 48px;
  cursor: pointer;
  border-left: 1px solid #d4d4d4;
  border-radius: 0 8px 8px 0;
  background: #fff 7px/7px 10px no-repeat;
}

.MuiTab-wrapper {
  display: contents !important
}

.MuiTab-root {
  min-height: 0px !important;
}

.mapboxgl-ctrl-top-right {
  top: 40px !important;
}

.mapIconButton {
  color: '#ffffff' !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  margin-right: 10px !important;
  width: 50px !important;
  height: 50px !important;
  min-height: 50px !important
}






.mapboxgl-ctrl-directions {
    min-width: 280px !important;
}

.mapboxgl-ctrl-group {
  border-radius: 50px !important;
  border-style: solid !important;
  border-width: 0px !important;
  background-color: rgba(0,0,0,0.4) !important;
}

 /* ESTILOS CONTROLE AB 
.mapboxgl-ctrl-geocoder {
  border-radius: 50px !important;
  border-style: solid !important;
  border-width: 0px !important;
  background-color: rgba(0,0,0,0.4) !important;
}
*/

.mapboxgl-ctrl-geocoder--icon-search {
  fill: #fff !important
}

.mapboxgl-ctrl-geocoder--input {
  color: #fff !important
}

.mapboxgl-ctrl-geocoder--icon {
  fill: #fff !important
}

.mapboxgl-ctrl-geocoder--button {
  background-color: rgba(0,0,0,0) !important;
}

.mapboxgl-ctrl-group button {
  width: 35px !important;
  height: 35px !important;
}

.mapboxgl-style-layer {
  border-radius: 5px !important;
  width: auto !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E %3C/svg%3E") !important;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E %3C/svg%3E") !important;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 0 0 5.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 0 0 9 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 0 0 3.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0 0 11 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z'/%3E %3Ccircle id='dot' cx='10' cy='10' r='2'/%3E %3Cpath id='stroke' d='M14 5l1 1-9 9-1-1 9-9z' display='none'/%3E %3C/svg%3E") !important;
}
.mapbox-gl-draw_point {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23fff">%3Cpath d="m10 2c-3.3 0-6 2.7-6 6s6 9 6 9 6-5.7 6-9-2.7-6-6-6zm0 2c2.1 0 3.8 1.7 3.8 3.8 0 1.5-1.8 3.9-2.9 5.2h-1.7c-1.1-1.4-2.9-3.8-2.9-5.2-.1-2.1 1.6-3.8 3.7-3.8z"/>%3C/svg>') !important;
}
.mapbox-gl-draw_polygon {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23fff">%3Cpath d="m15 12.3v-4.6c.6-.3 1-1 1-1.7 0-1.1-.9-2-2-2-.7 0-1.4.4-1.7 1h-4.6c-.3-.6-1-1-1.7-1-1.1 0-2 .9-2 2 0 .7.4 1.4 1 1.7v4.6c-.6.3-1 1-1 1.7 0 1.1.9 2 2 2 .7 0 1.4-.4 1.7-1h4.6c.3.6 1 1 1.7 1 1.1 0 2-.9 2-2 0-.7-.4-1.4-1-1.7zm-8-.3v-4l1-1h4l1 1v4l-1 1h-4z"/>%3C/svg>') !important;
}
.mapbox-gl-draw_line {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23fff">%3Cpath d="m13.5 3.5c-1.4 0-2.5 1.1-2.5 2.5 0 .3 0 .6.2.9l-3.8 3.8c-.3-.1-.6-.2-.9-.2-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5c0-.3 0-.6-.2-.9l3.8-3.8c.3.1.6.2.9.2 1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5z"/>%3C/svg>') !important;
}

.DeviceStatus-icon-legend {
  width: min-content;
  line-height: 14px;
  position: relative;
  top: -5px;
  margin-left: 10px;
  margin-right: 10px;
}

.MuiTypography-root {
  font-family: 'Proxima Nova Regular' !important;
  letter-spacing: 0 !important;
}

.MuiTypography-caption {
  font-weight: 600 !important;
}

.makeStyles-input-5 {
  background-color: #f5f5f5 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    box-shadow: 0 0 0 30px #f5f5f5 inset !important;
    -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
}

:root {
  --toastify-color-progress-dark: #78A9FF !important;;
}

::-webkit-scrollbar-thumb {
  border: 0;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

.MuiAutocomplete-popper {
  min-width: 400px;
}


/* unvisited link */
a:link {
  color: #78A9FF;
}

/* visited link */
a:visited {
  color: #78A9FF;
}

/* mouse over link */
a:hover {
  color: #78A9FF;
}

/* selected link */
a:active {
  color: #78A9FF;
}

.mapbox-directions-destination {
  z-index: 0;
}

.mapbox-directions-route-summary {
  z-index: 0 !important;
}
.MuiDataGrid-root {
  font-weight: normal !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.65rem;
  font-family: 'Proxima Nova Regular';
  background-color: #707070;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.5s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.react-datepicker {
  font-family: 'Proxima Nova Regular' !important;
  border: 1px solid rgba(0, 0, 0, 1) !important;
}

.react-datepicker__header {
  background-color: #78A9FF !important;
}

.react-datepicker__current-month {
  color: white !important;
}

.react-datepicker__day-name {
  color: white !important;
}

.react-datepicker__today-button {
  background-color: #78A9FF !important;
  color: white !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 20px !important;
  background-color: #78A9FF !important;
}


.react-datepicker__day--keyboard-selected {
  border-radius: 20px !important;
  background-color: #78A9FF !important;
  color: white !important;
}

.react-cookie-banner {
  display: inline-table !important;
  position: absolute !important;
  bottom: 0px !important;
}

.button-close {
  top: auto !important;
  bottom: 20% !important;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #78A9FF !important;
}

.MuiIconButton-colorPrimary {
  color: #78A9FF !important;
}

.MuiButton-outlinedPrimary {
  color: #78A9FF !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #78A9FF !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #78A9FF !important;
}

.MuiButton-containedPrimary {
  background-color: #78A9FF !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #78A9FF !important;
}

.MuiPickerDTTabs-tabs {
  background-color: #78A9FF !important;
}

.MuiPickersDay-daySelected {
  background-color: #78A9FF !important;
}

.MuiButton-textPrimary {
  color: #78A9FF !important;
}

.MuiFab-primary {
  color: #fff;
  background-color: #78A9FF !important;
}

.MuiDataGrid-columnHeaderTitle {
  white-space: normal !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  overflow: visible !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
  height: 56px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  min-height: 56px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #78A9FF !important;
}
